import React from "react";
import ListLinkSideBar from "../ListLinkSideBar";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";

export default function MenuGestor({
  hiddenMenuLabel = false,
  onMenuClick = (menuItem: string) => {},
}) {

  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem);
  };

  return (
    <>
      <ListLinkSideBar
        icon={<BarChartOutlinedIcon fontSize="medium" />}
        text={"Painel"}
        showText={!hiddenMenuLabel}
        link="/admin/painel"
        handleClick={() => handleMenuClick("painel")}
      /> 
    </>
  );
}
