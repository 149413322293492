import React from "react";
import ListLinkSideBar from "../ListLinkSideBar";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ListItemSideBar from "../ListItemSideBar";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Collapse from "@mui/material/Collapse";
import ListSubLinkSideBar from "../ListSubLinkSideBar";
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function MenuAdmin({
  hiddenMenuLabel = false,
  onMenuClick = (menuItem: string) => {},
}) {
  const [openConfig, setOpenConfig] = React.useState<boolean>(false);

  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem);
  };
  const openSubMenuConfig = () => {
    setOpenConfig(!openConfig);
  };

  return (
    <>
      <ListLinkSideBar
        //activeMenu={activeMenu === "scheduling"}
        icon={<CalendarMonthOutlinedIcon fontSize="medium" />}
        text={"Agendamentos"}
        showText={!hiddenMenuLabel}
        link="/agendamento"
        handleClick={() => handleMenuClick("/agendamento")}
      />
      <ListLinkSideBar
        icon={<PersonAddAlt1OutlinedIcon fontSize="medium" />}
        text={"Cadastro do Cidadão"}
        showText={!hiddenMenuLabel}
        link="/cadastro/cidadao"
        handleClick={() => handleMenuClick("/cadastro/cidadao")}
      />
      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<AddLocationAltOutlinedIcon fontSize="medium" />}
        text={"Cadastro do Rastreador"}
        showText={!hiddenMenuLabel}
        link="/cadastro/rastreador"
        handleClick={() => handleMenuClick("/cadastro/rastreador")}
      />
            {/* <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<WhereToVoteOutlinedIcon fontSize="medium" />}
        text={"Cadastro Completo"}
        showText={!hiddenMenuLabel}
        link="/cadastro/rastreador/completo"
        handleClick={() => handleMenuClick("tracks-register-complete")}
      /> */}
      <ListLinkSideBar
        //activeMenu={activeMenu === "rastreadores"}
        icon={<ShareLocationOutlinedIcon fontSize="medium" />}
        text={"Rastreadores"}
        showText={!hiddenMenuLabel}
        link="/admin/rastreadores"
        handleClick={() => handleMenuClick("/admin/rastreadores")}
      />
       <ListLinkSideBar
        icon={<TravelExploreIcon fontSize="medium" />}
        text={"Localizar Rastreador"}
        showText={!hiddenMenuLabel}
        link="/admin/rastreadores/localizar"
        handleClick={() => handleMenuClick("/admin/rastreadores/localizar")}
      />
        
      
      <ListLinkSideBar
        //activeMenu={activeMenu === "ativar-rastreamento"}
        icon={<TrackChangesIcon fontSize="medium" />}
        text={"Ativar Rastreamento"}
        showText={!hiddenMenuLabel}
        link="/ativar/rastreamento"
        handleClick={() => handleMenuClick("ativar-rastreamento")}
      />

      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<HomeRepairServiceOutlinedIcon fontSize="medium" />}
        text={"Serviços"}
        showText={!hiddenMenuLabel}
        link="/servicos"
        handleClick={() => handleMenuClick("servicos")}
      />

      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<BarChartOutlinedIcon fontSize="medium" />}
        text={"Painel"}
        showText={!hiddenMenuLabel}
        link="/admin/painel"
        handleClick={() => handleMenuClick("painel")}
      /> 

      <ListItemSideBar
        icon={<SettingsOutlinedIcon fontSize="medium" />}
        text={"Configurações"}
        showText={!hiddenMenuLabel}
        handleClick={openSubMenuConfig}
      />
      <Collapse in={openConfig} timeout="auto">
        <ListSubLinkSideBar
          //icon={<BusinessIcon fontSize="medium" />}
          text={"Cadastro Credenciada"}
          showText={!hiddenMenuLabel}
          link="/credenciadas/criar"
          handleClick={() => handleMenuClick("credenciada")}
        />
        <ListSubLinkSideBar
          //icon={<SupportAgentOutlinedIcon fontSize="medium" />}
          text={"Cadastro Operador"}
          showText={!hiddenMenuLabel}
          link="/operador"
          handleClick={() => handleMenuClick("/operador")}
        />
        <ListSubLinkSideBar
          //icon={<SupportAgentOutlinedIcon fontSize="medium" />}
          text={"Cadastro Teleatendente"}
          showText={!hiddenMenuLabel}
          link="/teleatendente"
          handleClick={() => handleMenuClick("/teleatendente")}
        />
        <ListSubLinkSideBar
          //icon={<SupportAgentOutlinedIcon fontSize="medium" />}
          text={"Cidadãos"}
          showText={!hiddenMenuLabel}
          link="/admin/cidadaos"
          handleClick={() => handleMenuClick("/admin/cidadaos")}
        />
      </Collapse>
    </>
  );
}
