import React, { useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import { useAuthetication } from "../../Contexts/AutheticationContext";
import MenuIntegrador from "./Groups/MenuIntegrador";
import MenuCidadão from "./Groups/MenuCidadão";
import MenuTeleatendende from "./Groups/MenuTeleatendende";
import MenuAdmin from "./Groups/MenuAdmin";
import MenuMonitoramento from "./Groups/MenuMonitoramento";
import MenuVistoria from "./Groups/MenuVistoria";
import MenuRecepcao from "./Groups/MenuRecepcao";
import MenuGestor from "./Groups/MenuGestor";

export default function Menu({hiddenMenuLabel=false, onMenuClick = ()=>{}}) {
  const {userGroup,checkIsAdmin, checkIsIntegrador, checkIsVistoria, checkIsRecepcao, checkIsGestor} = useAuthetication()   
  const handleMenuClick = (menuItem: string) => {

    onMenuClick()
  };


  const renderMenuByGroup = useCallback(() => {
    if (userGroup.length > 0) {
      if (checkIsAdmin()){
        return(<MenuAdmin hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      } else if (checkIsIntegrador())  {
        return( 
          <MenuIntegrador  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick}/>
        )
      }else if (userGroup.includes('teleatendente')){
        return( <MenuTeleatendende hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      }
      else if ( userGroup.includes('cidadão')) {
        return( <MenuCidadão  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      }else if ( userGroup.includes('monitoramento')) {
        return( <MenuMonitoramento  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      }
      else if ( checkIsVistoria()) {
       return( <MenuVistoria  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      }
      else if ( checkIsRecepcao()) {
        return( <MenuRecepcao  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
       }
       else if ( checkIsGestor()) {
        return( <MenuGestor hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
       }
    }
  }, [userGroup, hiddenMenuLabel]);

  

  return (
    <List sx={{width:'100%'}}>
        {renderMenuByGroup()}
    </List>
  );
}
