import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Card, Divider, Typography } from "@mui/material";
import MainLayout from "../../../core/Layout/MainLayout";

import { useForm } from 'react-hook-form';
import TitleLayout from "../../../core/Layout/TitleLayout";
import ContainerLayout from "../../../core/Layout/ContainerLayout";
import PrimaryResponsiveButton from "../../../components/Buttons/PrimaryResponsiveButton";
import SecondaryResponsiveButton from "../../../components/Buttons/SecondaryResponsiveButton";
import PlateFieldForm from "../../../components/Form/components/Fields/PlateFieldForm";
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchTrack, verifyPlate } from "../services";
import RegisterTrackTeleservicesForm from "../components/SearchTrack/Teleservices/RegisterTrackTeleservicesForm";
import SimpleInputFieldForm from "../../../components/Form/components/Fields/SimpleInputFieldForm";
import GridDataDisplay from "../../../components/DataDisplay/GridDataDisplay";
import { setCPFMask } from "../../../utils/CPFUtil";
import { setPhoneMask } from "../../../utils/PhoneUtil";
import { sanitizerName } from "../../../utils/StringsManipulationsUtil";


type Props = {
  readonly children: React.ReactNode, 
  
}
export function GridContainerResult ({children}: Props){
  return (
  <Grid container p={1}>
    <Grid size={{xs:12}}>
      <Card elevation={0} 
        sx={{
          borderRadius:'8px' ,
          p:1,
          width: '100%'
          }}> 
          {children}
      </Card>
    </Grid> 
  </Grid> )  

}
export default function SearchTracksPage() {

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm({ mode: "all" });

  const [processing, setProcessing] = useState<boolean>(false);

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [showHasNotSearch, setShowHasNotSearch] = useState<boolean>(true);
  const [citizien, setCitizien] = useState<any>(null);
  const [isPasswordVerified, setIsPasswordVerified] = useState<boolean>(false);
  const [showMensagemErroPassword, setShowMensagemErroPassword] = useState<boolean>(false);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
      noSsr: true
  });

  const submitForm = async () => {
    setProcessing(true);
    setIsPasswordVerified(false)
    //setShowHasNotSearch(false)
    setShowMensagemErroPassword(false)
    //setIsSearch(false)
      const response  = await SearchTrack(getValues('plate')?.replace('-', ''), getValues('keyword')) as any
      if(!response?.hasError){
       // setIsSearch(true)
        ///console.log(response.response.data.user.name,'response')
        if(response.response.data.user !== undefined){
          setIsPasswordVerified(true)
          setShowMensagemErroPassword(false)
        }else{
          setShowMensagemErroPassword(true)
        }
      }else{
        //setIsSearch(true)
       //setShowHasNotSearch(true)
       setIsPasswordVerified(false)
       setShowMensagemErroPassword(true)
        //setCitizien(null)
      }


    setProcessing(false);
  };

  const doVerifyPlate = async () => {
    setProcessing(true);

    setShowHasNotSearch(false)
    setIsSearch(false)
      const response  = await verifyPlate(getValues('plate')?.replace('-', '')) as any
      if(!response?.hasError){
        setIsSearch(true)
        ///console.log(response.response.data.user.name,'response')
        if(response.response.data.user !== undefined){
          setCitizien(response.response.data.user)
        }
      }else{
        setIsSearch(true)
        setShowHasNotSearch(true)
        setCitizien(null)
      }


    setProcessing(false);
  };


  const clearFileds = () => {
    setValue('plate', '')
    setValue('keyword', '')
    setIsSearch(false)
    setShowHasNotSearch(true)
    setIsPasswordVerified(false)
  }

  const clearKeyWord = () => {
   
    setValue('keyword', '')
    setIsPasswordVerified(false)
  }

  return (
    <MainLayout>
       <TitleLayout >
          Ativar Rastreamento
        </TitleLayout>
    
            <ContainerLayout minHeight="auto" loading={processing}>
            
              {/* <Grid container justifyContent={"center"} >
                <Grid size={{xs:12, md: 5}}> */}
                
                <Grid container component="form" sx={{padding: 2, width: '100%'}} spacing={2} >
                  <Grid size={{xs:12, md:3}} >
                    <PlateFieldForm
                      register={register}
                      control={control}
                      name='plate'
                      value={getValues('plate')}
                      setValue={setValue}
                      id='plate'
                      required={true}
                      disabled={!showHasNotSearch}
                    />
                  </Grid>
               
                  <Grid size={{xs:12, md:6}} >
                    <Grid container spacing={2} sx={{  height: isMobile? 'auto': '40px' }} alignItems={'center'} justifyContent={isMobile?'center':'flex-start'}>
                
                      <SecondaryResponsiveButton onClick={clearFileds}>
                        Limpar
                      </SecondaryResponsiveButton>
                  
                      <PrimaryResponsiveButton  onClick={doVerifyPlate}> 
                        <SearchIcon />Buscar
                      </PrimaryResponsiveButton>
                  </Grid>
                </Grid> 
                <Grid size={{xs:12}}>
                    <Divider/>
                  </Grid>
                </Grid>
               
             
                  {isSearch && ( 
                  <>
                      {!showHasNotSearch ? (
                        <>
                        <GridContainerResult>

                          <Typography component='p' sx={{margin: 0, marginBottom:1,  marginTop:1}}>
                            Essa placa está cadastrada no programa Moto Segura, vinculada ao cidadão:         
                          </Typography>
                          <Grid container sx={{pb:1}}>
                            <Grid size={{xs:12, md:4}}>
                              <GridDataDisplay titulo="Nome" info={sanitizerName(citizien?.name)}/>
                              <GridDataDisplay titulo="CPF" info={setCPFMask(citizien?.cpf)}/>
                              <GridDataDisplay titulo="Telefone" info={setPhoneMask(citizien?.phone)}/>
                            </Grid>
                          </Grid>
                        
                          
                            <Grid size={{xs:12, md:12}} sx={{ pb:2, pt:1}}>
                    <Grid container spacing={2} sx={{  height: isMobile? 'auto': '40px'}} alignItems={'center'} justifyContent={isMobile?'center':'flex-start'}>
                    <Grid size={{xs:12, md:3}} >
                              <SimpleInputFieldForm
                                label="Chave de Rastreamento"
                                name="keyword"
                                register={register}
                                control={control}
                                messageError={"O campo não pode ser vazio"}
                                setValue={setValue}
                                id='keyword'
                                value={getValues('keyword')}
                                required={true}
                                disabled={isPasswordVerified}
                                
                              />
                            </Grid>
                      <SecondaryResponsiveButton onClick={clearKeyWord}>
                        Limpar
                      </SecondaryResponsiveButton>
                  
                      <PrimaryResponsiveButton  onClick={submitForm}> 
                        <SearchIcon />Buscar
                      </PrimaryResponsiveButton>
                  </Grid>
                    </Grid>
                          {isPasswordVerified && (

                            <RegisterTrackTeleservicesForm plate={getValues('plate')} keyword={getValues('keyword')}/>
                          )} 
                          
                          {!isPasswordVerified && showMensagemErroPassword && (
                            <Typography component='h6' sx={{margin: 0, marginBottom:2,  marginTop:2}}>
                              Atenção: A palavra-chave está incorreta.                      
                          </Typography>
                          )}
                        </GridContainerResult>
                        </>
                      ):(
                        <GridContainerResult>
                          <Typography component='h6' sx={{margin: 0, marginBottom:2,  marginTop:2}}>
                              Não existe essa placa e/ou chave de rastreamento cadastrado no programa Moto Segura.                      
                          </Typography>
                        </GridContainerResult>
                      )}
                 </>
                )}   
          
            </ContainerLayout>

    </MainLayout>
  );
}

